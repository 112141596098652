import File from "../files/file";
import Metal from "../materials/metal";
import metal from "../materials/metal";
import SideStone from "../side_stone/side_stone";
import BandMetal from "./band_metal";
import DiamondOption from "./diamond_option";

export default class Band{

    id?: number;
    name?: string;
    slug?: string;
    permalink?: string;
    price?: string;
    short_description?: string;
    image?: File;
    model?: File;
    setting_offset?: number;
    metals?: Array<BandMetal>;
    prong?: number;
    side_stones?: Array<SideStone>;
    diamond_options?: Array<DiamondOption>;
    is_wedding_band?: boolean;
    matching_wedding_bands?: Array<Band>;
    archive?: boolean;

    static fromJSON(d: Object): Band {
        return Object.assign(new Band(), d);
    }

    public getSelectedMetal(selectedMetal: metal): BandMetal | undefined {
        if(this.metals){
            for(const metal of this.metals){
                if(metal.id == selectedMetal.id){
                    return metal
                }
            }
        }
        return undefined
    }

    public getWeddingBand(): Band|undefined{
        if(this.matching_wedding_bands && this.matching_wedding_bands.length > 0){
            return this.matching_wedding_bands[0];
        }
        return undefined;
    }

    public getPriceString(metal:Metal|undefined = undefined) {
        let p = Number(this.price ?? 0);
        // console.log('finding band metal', metal);
        if(metal && metal.id){
            const m = this.getMetalByID(metal.id);
            if(m){
                p += metal.priceFor(m.weight??0);
            }
        }
        return '$'+Number(this.price).toLocaleString('en-US',{maximumFractionDigits:0})+' NZD';
        
        // Math.round(p || 0)+' NZD';
        // (p || 0).toLocaleString('en-US',{minimumFractionDigits:2, maximumFractionDigits:2})
      }
    private getMetalByID(id:number){
        // console.log('finding metal', id, this.metals??[]);
        for(const m of this.metals??[]){
            if(m.metalID == id){
                return m;
            }
        }
    }
}



import BandSize from "./band_size";

export default class BandSizeGroup{
    id?: number;
    name?: string;
    price?: number;
    sizes?: Array<BandSize>;
    static fromJSON(d: Object): BandSizeGroup {
        return Object.assign(new BandSizeGroup(), d);
    }
}
import DataManager from "../data_manager";

//Directus file
export default class File{
    id?: string;
    filename_disk?: string;
    title?: string;
    type?: string;
    name?: string;
    // src?: string;
    static fromJSON(d: Object): File {
        return Object.assign(new File(), d);
    }

    public src(){
        if(this.filename_disk !== undefined || this.id !== undefined){
            return DataManager.getInstance().getFileUrl(this.filename_disk??this.id??"");
        }
        return "";
    }
}
import StoneImage from "./stone_image";
import Measurement from "./measurement";
import File from "../files/file";
import DataManager from "../data_manager";
import MetaData from "./meta_data";

export default class Stone{
    id?: number;
    name?: string;
    slug?: string;
    permalink?: string;
    price?: string;
    description?: string;
    short_description?: string;
    sku?: string;
    images?: StoneImage[];
    stone_type?: number;
    gem_color?: string;
    emissive_color?: string;
    emissive_intensity?: number;
    measurement?: Measurement;
    metallic?: number;
    roughness?: number;
    gem_view?: File;
    archived?: boolean;
    meta_data?: Array<MetaData>;
    vault?: boolean;
    static fromJSON(d: Object): Stone {
        return Object.assign(new Stone(), d);
    }

    public getNameTitle(){
        const elements:Array<string> = this.getTitleElements();
        if(elements.length > 0){
            return Stone.getSentenceCase(this.getTitleElements()[0]);
        }
        return '';
    }
    public getCaratTitle(){
        const elements:Array<string> = this.getTitleElements();
        if(elements.length > 1){
            return Stone.getSentenceCase(this.getTitleElements()[1].replaceAll(' ', ''));
        }
        return '';
    }
    public getTitle(){
        return this.getTitleElements().join(' ');
    }

    private getTitleElements():Array<string>{
        if(this.short_description){
            let source = this.short_description.replace(/<(.|\n)*?>/g, '');
            source = source.replaceAll('CUT', '');
            source = source.replaceAll('CARAT', 'ct');
            while(source.indexOf('  ') != -1){
                source = source.replaceAll('  ', ' ');
            }

            if(source.indexOf("|") > 0){
                return source.split('|').slice(1);
            }
            
            return [source];
        }
        return [];
    }
    
    public getPriceString():string{
        if(this.vault){
            return 'IN VAULT';
        }

        return '$'+Number(this.price).toLocaleString('en-US',{maximumFractionDigits:0})+' NZD';
    }

    public getDetails():string{

        let result:string = '';
        if(this.sku){
            result += this.getRow('ID', this.sku??'');
        }
        for(const md of this.meta_data??[]){
            console.log('meta_data', md);
            if((md.key??'').startsWith('product_')){
                let title = (md.key??'').substring(8);
                if(title.toLowerCase() == 'cutshape'){
                    title = 'Shape';
                }
                result += this.getRow(Stone.getSentenceCase(title), md.value??'');
            }
        }

        
        // const details = [];
        // if(this.short_description){
        //   details.push(...DataManager.removeHtml(this.short_description).split('|'));
        // }
        // for(const s of details){
        //   if(details.indexOf(s) == 0){
        //     result += this.getRow('ID', s);
        //   }else{
        //     result += this.getElementDetails(s);
        //   }
        // }

        return result;
      }

    //   private getElementDetails(source:string):string{
    //     console.log('getting element from', source);
    //     if(source.indexOf(' ') != -1){
    //       let elementStr = source.trim().replaceAll(/(\s+)/g, ' ');
    //       while(elementStr.indexOf('  ') != -1){
    //         elementStr = elementStr.replaceAll('  ', ' ');
    //       }
    //       const elements = elementStr.split(' ');
    //       console.log('after split', elements.join('='), elements.length);
    //       if(elements.length == 2){
    //         return this.getRow(elements[1], elements[0]);
    //       }
    //       return this.getRow(elementStr, "");
    //     }
    //     return source;
    //   }
    
      private getRow(title:string, name:string){
        return title+': '+name+'<br>\n';
      }

    public static getSentenceCase(input:string):string{
        const rg = /(^\s*\w{1}|\.\s*\w{1})/gi;
        return input.toLowerCase().trim().replace(rg, function(toReplace) {
            return toReplace.toUpperCase();
        });
    }
}
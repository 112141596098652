import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify/lib'
import vb from 'vue-babylonjs';
import VueTagManager from 'vue-tag-manager';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'a-scene',
  'a-sky',
  'a-entity',
  'a-camera',
  'a-box',
  'a-cylinder',
  'a-sphere',
  'a-assets',
  'a-asset-item',
  'a-entity'
]
Vue.use(vb);
Vue.use(Vuetify);
Vue.use(VueTagManager, {
  gtmId: 'GTM-WDHKZTR'
});
Vue.use(VueMeta);
export const dataBus = new Vue();
const vuetify = new Vuetify(  {
  theme: {
    themes: {
      light: {
        primary: '#da5969',
        secondary: '#1c3557',
        accent: '#066a8a',
        error: '#da5969',
        color: '#252d47'
      }
    }
  }
});
new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#app')

export default class BandMetal{
    id?: number;
    price?: number;
    metalID?: number;
    weight?: number;
    static fromJSON(d: Object): BandMetal {
        return Object.assign(new BandMetal(), d);
    }
}



import { BABYLON } from "vue-babylonjs";
import { 
    Scene, 
    Mesh, 
    ISceneLoaderAsyncResult, 
    InstancedMesh, 
    Vector3, 
    Color3,
    PointLight, 
    Material,
    Camera,
    Nullable,
    AbstractMesh} from "babylonjs";
import Band from "@/model/band/band";
import Prong from "@/model/stone/prong";
import DataManager from "@/model/data_manager";
import ProngLocation from "@/model/stone/prong_location";
import StoneType from "@/model/stone/stone_type";
import SideStone from "@/model/side_stone/side_stone";
import Stone from "@/model/stone/stone";
import image from "@/model/files/image";
import { MaterialController } from "./mateiral_controller";
import Measurement from "@/model/stone/measurement";
import File from "@/model/files/file";


export class SceneController{
    ///////////////////  SINGLETON  //////////////////////
    private static instance:SceneController;
    private constructor() { }
    public static getInstance(): SceneController {
        if (!SceneController.instance) {
            SceneController.instance = new SceneController();
        }
        return SceneController.instance;
    }
    /////////////////////   DATA   ////////////////////////
    private scene : Scene | undefined;
    public totalLights : number = 4;
    private gems:Map<StoneType, Array<Mesh>> = new Map<StoneType, Array<Mesh>> ();
    private currentBand?:Array<Mesh>;
    private weddingBand?:Array<Mesh>;
    private currentStone: Array<Mesh> | undefined;
    private sideStones:Array<Array<Mesh>> = [];
    // private prongs: Array<Mesh> = Array<Mesh>();
    private prongInstances:InstancedMesh[] = [];
    private mainCamera: Nullable<Camera> = null;
    //2d gem view:
    private gemViewVisible = false;
    private gemView : Mesh | undefined;
    private front:Vector3 = new BABYLON.Vector3(0, 0, 1);
    
    private gemViewLimit = 15*((Math.PI)/180); //10 digress

    private updatingNow = false;

    private bottomPadding: number = 0;

    
    /////////////////////  PUBLIC  ////////////////////////

    public sceneCreated(scene:Scene){
        // console.log("Scene loaded");
        this.scene = scene;
        this.mainCamera = this.scene.activeCamera;
        this.addLighting();
        // console.log("total lights", this.totalLights);
        this.scene.createDefaultEnvironment({createGround:false,createSkybox:false})
        this.scene.actionManager = new BABYLON.ActionManager(scene);
        const self = this;
        this.scene.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
                {
                    trigger: BABYLON.ActionManager.OnEveryFrameTrigger,
                },
                ()=>self.frameUpdate()
            )
        );
    }

    public isCurrent(scene:Scene):boolean{
        return this.scene == scene;
    }

    ///In the event of scene size change force scene
    ///to resize
    public bottomPaddingUpdated(bottomPadding:number):void{
        if(bottomPadding != this.bottomPadding){
            this.bottomPadding = bottomPadding;
            console.log('scene resized');
            if(this.scene){
                this.scene.getEngine().resize();
            }
        }
    }


    public async updateScene(){
        // console.log("Scene updated");
        //remove old models
        this.removeAllLoaded();
        BABYLON.SceneLoader.skipMaterials  = true;
        // console.log("LOADING SCENE");
        // console.log(this.scene);
        //add new models
        const dm:DataManager = DataManager.getInstance();
        if(dm.selectedBand){
            const band = dm.selectedBand;
            this.currentBand = await this.addBand(band);

            if(dm.includeWeddingBand??false){
                const wb: Band|undefined = band.getWeddingBand();
                if(wb){
                    this.weddingBand = await this.addBand(wb);
                }
            }
            this.showWeddingBand(dm.includeWeddingBand??false);
            let scale:Vector3 = new BABYLON.Vector3(1,1,1);
            //add dynamic elements
            if(dm.selectedStone && dm.selectedStone.stone_type){
                const stoneType = dm.stoneTypeByID(dm.selectedStone.stone_type);
                if(stoneType){
                    scale = this.getStoneScale(stoneType);
                    const measurement:Measurement|undefined = dm.selectedStone.measurement??stoneType.measurement;
                    const offset = band.setting_offset??10.5;
                    //add current stone
                    await this.addMainStone(stoneType, scale, offset);
                    await this.updateGemView(dm.selectedStone, measurement, offset);
                    await this.addProngs(band, stoneType, scale, offset);
                    await this.addSideStones(band, measurement, offset);
                }
            }else if(this.gemView){
                this.gemView.isVisible = false;
                this.gemView = undefined;
            }
        }
        // console.log("DONE LOADING SCENE");
    }

    private async addBand(band:Band):Promise<Array<Mesh>>{
            if(band.model?.filename_disk){
             return this.addMeshFromURL(DataManager.getInstance().getAssetUrl(), band.model?.filename_disk);
            }
            return [];
    }
                

    public showWeddingBand(show:boolean):void{
        if(this.weddingBand){
            this.setVisibleOn(this.weddingBand, show);
        }
    }

    //////////////////    Internal     ///////////////////////

    private frameUpdate(){
        if(this.mainCamera){
            const direction:Vector3 = this.mainCamera.position;
            const normal = BABYLON.Vector3.Cross(direction, this.front).normalize();
            const angle = BABYLON.Vector3.GetAngleBetweenVectors(
                direction, this.front, normal
            );
            if(this.gemViewVisible && angle > this.gemViewLimit){
                this.hideGemView();
            }
            if(!this.gemViewVisible && angle < this.gemViewLimit){
                this.showGemView();
            }

        }
    }


    private addLighting(){
        this.addHemisphereLight(
            new BABYLON.Vector3(0, 1, 0),
            1
        );
        this.addHemisphereLight(
            new BABYLON.Vector3(0, -1, 0),
            0.3
        );
        this.addLightRing(4, 0, 40, Math.PI*0.5);
        // this.addLightRing(4, -10, 40, Math.PI*0.5);
    }

    private addHemisphereLight(
        direction:Vector3, 
        intensity:number = 6,
        diffuse:Color3 = new BABYLON.Color3(1, 1, 1),
        specular:Color3 = new BABYLON.Color3(0.1, 0.1, 0.1),
        groundColor:Color3 = new BABYLON.Color3(0, 0, 0),
        ){
        const light = new BABYLON.HemisphericLight("MainLight", direction, this.scene);
        light.intensity = intensity;
        light.diffuse = diffuse;
        light.specular = specular;
        light.groundColor = groundColor;
        // console.log("Light added");
        this.totalLights++;
    }

    private addLightRing(
        count: number, 
        height: number, 
        radius:number, 
        start:number = 0, 
        debug:boolean = false){
        let a = start;
        const s = (2 * Math.PI)/(count-1);
        for(let i = 0; i < count; i++){
            const x = radius * Math.cos(a);
            const z = radius * Math.sin(a);
            const light:PointLight = new BABYLON.PointLight("light_"+i+"_"+height, new BABYLON.Vector3(x, height, z), this.scene);
            light.intensity = 100;
            light.range = radius;
            if(debug){
                const sphere = BABYLON.MeshBuilder.CreateSphere("sphere", {}, this.scene);
                sphere.position = new BABYLON.Vector3(x, height-1, z);
            }
            a+=s;
            this.totalLights++;
        }
    }


    private getStoneScale(stoneType:StoneType):Vector3{
        const dm:DataManager = DataManager.getInstance();
            if(stoneType && stoneType.measurement && dm.selectedStone && dm.selectedStone.measurement){
                return new BABYLON.Vector3(
                    (dm.selectedStone.measurement.width??1.0)/(stoneType.measurement.width??1.0), 
                    (dm.selectedStone.measurement.height??1.0)/(stoneType.measurement.height??1.0), 
                    (dm.selectedStone.measurement.depth??1.0)/(stoneType.measurement.depth??1.0));
            }
        return new BABYLON.Vector3(1,1,1);
    }


    private async addMainStone(stoneType:StoneType, scale:Vector3, offset:number){
        
        this.currentStone = await this.getStoneModel(stoneType);
        if(this.currentStone){
            for(const m of this.currentStone){
                // console.log(m.id);
                if(m.id != '__root__'){
                    m.scaling = new BABYLON.Vector3(scale.x, scale.z, scale.y) ;
                    m.position = new BABYLON.Vector3(0, 0, offset);
                    m.isVisible = true;
                }
            }
        }
    }

    private async updateGemView(stone:Stone, measurement:Measurement|undefined, offset:number){
        if(stone.gem_view){
            if(!this.gemView){
                //add gem plane
                this.gemView = BABYLON.MeshBuilder.CreatePlane("gem_view", {sideOrientation: BABYLON.Mesh.DOUBLESIDE}, this.scene); 
            }
            if(this.gemView){
                this.gemView!.rotation = new BABYLON.Vector3(0, 0, 0);
                //update position
                if(measurement){
                    this.gemView.scaling = new BABYLON.Vector3(measurement.width, measurement.height, 1.0) ;
                }
                this.gemView.position = new BABYLON.Vector3(0, 0, offset);
                this.gemView.isVisible = false;
                //set material
                this.gemView.material = MaterialController.getInstance().getImageMaterial(stone.gem_view, 'gem_view');
                this.gemViewVisible = false;
            }
        }else if(this.gemView){
            this.gemView.isVisible = false;
            this.gemView = undefined;
        }
    }
    private showGemView() {
        if(this.gemView){
            this.gemView.isVisible = true;
            if(this.currentStone){
                this.hideModels(this.currentStone);
            }
            this.gemViewVisible = true;
        }
    }
    private hideGemView() {
        if(this.gemView){
            this.gemView.isVisible = false;
            if(this.currentStone){
                this.showModels(this.currentStone);
            }
            this.gemViewVisible = false;
        }
    }
    
    
    private async addProngs(band:Band, stoneType:StoneType, scale:Vector3, offset:number){
        let prong: Prong | null = null;
        if(band.prong){
            prong = DataManager.getInstance().prongByID(band.prong);
        }
        //add prongs
        if(prong){
            // console.log("updating prongs");
            if(stoneType.prong_locations){
                const prongRoot = await this.getProngs(prong);
                if(prongRoot){
                    this.hideModels(prongRoot);
                    this.instanceModels(prongRoot, stoneType.prong_locations, scale, offset);
                }
            }
        }
    }

    private hideModels(models:any[]){
        this.setVisibleOn(models, false);
    }

    private showModels(models:any[]){
        this.setVisibleOn(models, true);
    }

    private setVisibleOn(models:any[], visible:boolean){
        for(const model of models){
            model.isVisible = visible;
        }
    }

    private instanceModels(models:any[], positions:Array<ProngLocation>|null, scale:Vector3, offset:number){
        if(positions){
            for(const l of positions){
                for(const model of models){
                    const i = model.createInstance("prong_"+(l.id??""));
                    i.isVisible = true;
                    // console.log("prong position scale", scale);
                    this.positionInstance(i, l, scale, offset);
                    this.prongInstances.push(i);
                }
            }
        }
    }


    private async addSideStones(band:Band, measurement:Measurement|undefined, offset:number){
        // console.log("adding side stones", band.side_stones);
        if(band.side_stones){
            for(const s of band.side_stones){
                await this.addSideStone(s, measurement, offset);
            }
        }
    }

    
    private async addSideStone(sideStone:SideStone, measurement:Measurement|undefined, offset:number){
        if(sideStone){
            // console.log("adding side stone", sideStone);
            const model:any[]|null = await this.getModels(sideStone);
            if(model && model.length > 0){
                const sideLocation:Vector3 = this.getSidePosition(sideStone, measurement, offset);
                this.positionModel(model, sideLocation);
                // model[0].translate(sideLocation, 1.0, BABYLON.Space.LOCAL);
                this.sideStones.push(model);
            }
        }
    }
    
    private positionModel(meshes:Array<AbstractMesh>, location:Vector3){
        for(const m of meshes){
            m.translate(location, 1.0, BABYLON.Space.LOCAL);
        }
    }

    private getSidePosition(sideStone:SideStone, measurement:Measurement|undefined, offset:Number):Vector3{
        let x = 0;
        let z = 0;
        if(measurement){
            switch(sideStone.Direction){
                case 'up':
                    z = -(measurement.height??0)/2;
                    break;
                case 'right':
                    x = (measurement.width??0)/2;
                    break;
                case 'down':
                    z = (measurement.height??0)/2;
                    break;
                case 'left':
                    x = -(measurement.width??0)/2;
                    break;
            }
        }
        return new BABYLON.Vector3(
            x, offset, z
        );
    }

    private positionInstance(instance:any, prongLocation:ProngLocation, scale:Vector3, prongOffset: number){
        const shift = new BABYLON.Vector3(
            (prongLocation.x??0)*scale.x, 
            (prongLocation.y??0)*scale.y, 
            prongOffset);//*scale.z);

        instance.translate(shift, 1.0, BABYLON.Space.LOCAL);
        instance.rotate(BABYLON.Axis.Z, -this.degreesToRadians(prongLocation.rotation??0), BABYLON.Space.WORLD);
    }

    private async getProngs(prong: Prong):Promise<any[] | null>{
        return this.getModels(prong);
    }

    private async getModels(source: any):Promise<any[] | null>{
        if(source && source.model && source.model.filename_disk){
            const meshes = await this.addMeshFromURL(DataManager.getInstance().getAssetUrl(), source.model.filename_disk);
            return meshes.slice(1);
        }
        return null;
    }



    private async getStoneModel(stoneType:StoneType):Promise<Mesh[] | undefined>{
        if(!this.gems.has(stoneType)){
            if(stoneType.model && stoneType.model.filename_disk){
                const res = await this.addMeshFromURL(DataManager.getInstance().getAssetUrl(), stoneType.model.filename_disk);
                this.gems.set(stoneType, res);
            }
        }
        return this.gems.get(stoneType);
    }

    private degreesToRadians(degrees:number){
        return degrees * (Math.PI/180);
    }

    private async addMeshFromURL(path:string, filename:string):Promise<Array<Mesh>>{
        const res:ISceneLoaderAsyncResult = await BABYLON.SceneLoader.ImportMeshAsync(null, path, filename);
        const meshes = res.meshes as Array<Mesh>;
        return meshes
    }

    private removeAllLoaded(){
        //hide all gem types
        for (const entry of Array.from(this.gems.entries())) {
            const gems = entry[1];
            for(const m of gems){
                m.isVisible = false;
            }
        }

        //remove all prong instances
        if(this.prongInstances){
            this.removeAll(this.prongInstances);
        }

        //remove bands
        if(this.currentBand){
            this.removeAll(this.currentBand);
        }
        if(this.weddingBand){
            this.removeAll(this.weddingBand);
        }

        //remove side stones
        for(const s of this.sideStones){
            this.removeAll(s);
        }
    }

    private removeAll(meshes:Array<AbstractMesh>){
        for(const mesh of meshes){
            mesh.isVisible = false;
            mesh.dispose();
        }
    }

}
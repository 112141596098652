
import File from "../files/file";

export default class Prong{
    id?: number;
    name?: string;
    model?: File;
    static fromJSON(d: Object): Prong {
        return Object.assign(new Prong(), d);
    }
}
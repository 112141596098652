import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=dedba336&scoped=true&lang=pug&"
import script from "./PageHeader.vue?vue&type=script&script=true&lang=ts&"
export * from "./PageHeader.vue?vue&type=script&script=true&lang=ts&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=dedba336&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dedba336",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VImg,VRow,VSpacer})

import File from "../files/file";
import ProngLocation from "./prong_location";
import Measurement from "./measurement";

export default class StoneType{
    id?: number;
    name?: string;
    model?: File;
    prong_locations?: Array<ProngLocation>;
    measurement?: Measurement;
    static fromJSON(d: Object): StoneType {
        return Object.assign(new StoneType(), d);
    }
}


export default class Metal{

    id?: number;
    name?: string;
    primary_color?: string;
    base_color?: string;
    metallic?: number;
    roughness?: number;
    Price?: number;
    static fromJSON(d: Object): Metal {
        return Object.assign(new Metal(), d);
    }

    priceFor(weight: number): number{
        return weight*(this.Price??0);
    }
    
}
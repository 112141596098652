import { render, staticRenderFns } from "./FontSelection.vue?vue&type=template&id=344a4ff6&scoped=true&lang=pug&"
import script from "./FontSelection.vue?vue&type=script&script=true&lang=ts&"
export * from "./FontSelection.vue?vue&type=script&script=true&lang=ts&"
import style0 from "./FontSelection.vue?vue&type=style&index=0&id=344a4ff6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "344a4ff6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VList,VListItem,VListItemTitle,VMenu})

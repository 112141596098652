import File from "../files/file";
import ProngLocation from "../stone/prong_location";

export default class SideStone{
    id?: number;
    name?: string;
    model?: File;
    Direction?: string;
    static fromJSON(d: Object): SideStone {
        return Object.assign(new SideStone(), d);
    }
}

class QueuedFile{
    constructor(url:string, onDone?:()=>void){
        this.url = url;
        this.onDone = onDone;
    }
    url: string = '';
    onDone?: ()=>void;
}

export class FileCache{
    ///////////////////  SINGLETON  //////////////////////
    private static instance:FileCache;
    private constructor() { }
    public static getInstance(): FileCache {
        if (!FileCache.instance) {
            FileCache.instance = new FileCache();
        }
        return FileCache.instance;
    }

    private fileQueue: QueuedFile[] = [];

    ///////////////////  PUBLIC  //////////////////////

    

    /// add a fo;e tp the end of the queue
    public queueFile(url: string, onDone?:()=>void):void{
        this.fileQueue.splice(0, 0, new QueuedFile(url, onDone));
        this.checkQueue();
    }

    /// queue a file to be downloaded next in the queue
    public File(url: string, onDone?:()=>void):void{
        this.fileQueue.push(new QueuedFile(url, onDone));
        this.checkQueue();
    }

    ///////////////////  PRIVATE  //////////////////////

    private downloading: boolean = false;
    private checkQueue():void{
        if(this.downloading){
            // console.log('did not start, already downloading.');
            return;
        }
        if(this.fileQueue.length > 0){
            
            this.downloading = true;
            const file = this.fileQueue.pop();
            // console.log('starting download of ', file?.url);
            if(file){
                this.downloadFile(file).then(()=>{
                    
                    // console.log('finished download of ', file?.url);
                    // console.log('queue remaining ', this.fileQueue.length);
                    this.downloading = false;
                    this.checkQueue();
                });
            }
        }
    }

    private async downloadFile(file:QueuedFile):Promise<void>{
        try{
            await fetch(file.url);
            if(file.onDone){
                file.onDone();
            }
        }catch(exception){
            //downloading file
            console.log('failed to download ', file.url);
        }
    }
}
import { render, staticRenderFns } from "./MetalSelectionDisplay.vue?vue&type=template&id=16b7876c&lang=pug&"
import script from "./MetalSelectionDisplay.vue?vue&type=script&script=true&lang=ts&"
export * from "./MetalSelectionDisplay.vue?vue&type=script&script=true&lang=ts&"
import style0 from "./MetalSelectionDisplay.vue?vue&type=style&index=0&id=16b7876c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})

import { render, staticRenderFns } from "./RingView.vue?vue&type=template&id=0b43835d&scoped=true&lang=pug&"
import script from "./RingView.vue?vue&type=script&lang=ts&"
export * from "./RingView.vue?vue&type=script&lang=ts&"
import style0 from "./RingView.vue?vue&type=style&index=0&id=0b43835d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b43835d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCol,VImg})


///development
// export default class SiteConfig{
//     static VUE_APP_BACKEND_URL:string='https://rings.littlemonkey.info';
//     static VUE_APP_WP_URL:string='https://staging-hartandhalo.studioeleven.co.nz';
//     static VUE_APP_URL:string='https://ringbuilder.littlemonkey.info/';
// }

//live
export default class SiteConfig{
    static VUE_APP_BACKEND_URL:string='https://designer-admin.hartandhalo.co.nz';
    static VUE_APP_WP_URL:string='https://hartandhalo.co.nz';
    static VUE_APP_URL:string='https://designer.hartandhalo.co.nz';
}